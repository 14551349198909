import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Divider from "@material-ui/core/Divider"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Link from "@material-ui/core/Link"
import Radio from "@material-ui/core/Radio"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import anime from "animejs/lib/anime.es.js"
import axios from "axios"
import { Field, Form, Formik } from "formik"
import { CheckboxWithLabel, RadioGroup, TextField } from "formik-material-ui"
import { graphql, Link as GatsbyLink, navigate } from "gatsby"
import lottie from "lottie-web"
import mixpanel from "mixpanel-browser"
import { useSnackbar } from "notistack"
import React from "react"
import Helmet from "react-helmet"
import * as Yup from "yup"
import exclamationAnimation from "../animations/exclamation.json"
import Action from "../components/action"
import AnimateOnSeen from "../components/animate-on-seen"
import BigForm from "../components/big-form"
import CTAButton from "../components/CTAButton"
import DialogTitle from "../components/dialog-title"
import Page from "../components/page"
import PhoneMaskedInput from "../components/phone-masked-input"
import { Statistics } from "../components/statistics"
import {
  DOT_MAX_LENGTH,
  DOT_MIN_LENGTH,
  DOT_REGEX,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PHONE_NUMBER_REGEX,
  TRANSLATE_ANIME_CONFIG,
} from "../constants"
import { buildMeta, formatError } from "../helpers"
import AppStoreBadgeImg from "../img/app-store-badge.png"
import DriverLogoFullImg from "../img/driver-logo-full.png"
import GooglePlayBadgeImg from "../img/google-play-badge.png"
import LogoCTMSFullImg from "../img/logo-carrier-tms-full.svg"
import LogoCTMSSmallImg from "../img/logo-carrier-tms-small.svg"
import LogoFullWhite from "../img/logo-full-white.svg"
import LogoFullImg from "../img/logo-full.png"

const AdapterLink = React.forwardRef((props, ref) => (
  <GatsbyLink innerRef={ref} {...props} />
))

const PASSWORD_VALIDATION_MESSAGE = `Must contain ${PASSWORD_MIN_LENGTH}-${PASSWORD_MAX_LENGTH} characters and at least 1 letter`
const DOT_LENGTH_VALIDATION_MESSAGE = `Must contain ${DOT_MIN_LENGTH}-${DOT_MAX_LENGTH} digits`
const SignupCarrierSchema = Yup.object()
  .shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Required"),
    password: Yup.string()
      .matches(/[^\d]/, PASSWORD_VALIDATION_MESSAGE)
      .min(PASSWORD_MIN_LENGTH, PASSWORD_VALIDATION_MESSAGE)
      .max(PASSWORD_MAX_LENGTH, PASSWORD_VALIDATION_MESSAGE)
      .required("Required"),
    phone: Yup.string()
      .matches(PHONE_NUMBER_REGEX, "Invalid phone number. Please verify.")
      .required("Required"),
    companyName: Yup.string().required("Required"),
    dotNumber: Yup.string()
      .matches(DOT_REGEX, "US DOT number can contain digits only.")
      .min(DOT_MIN_LENGTH, DOT_LENGTH_VALIDATION_MESSAGE)
      .max(DOT_MAX_LENGTH, DOT_LENGTH_VALIDATION_MESSAGE),
    roles: Yup.object()
      .shape({
        owner: Yup.boolean(),
        dispatcher: Yup.boolean(),
        accountant: Yup.boolean(),
        driver: Yup.boolean(),
        supervisor: Yup.boolean(),
      })
      .test("rolesTest", "Required", value => {
        return (
          value.owner ||
          value.dispatcher ||
          value.accountant ||
          value.driver ||
          value.supervisor
        )
      }),
    terms: Yup.boolean().oneOf([true], "Required"),
    companyType: Yup.string(),
    companyTypeDescription: Yup.string(),
  })
  .test("companyTypeTest", "Required", value => {
    if (!value.dotNumber) {
      if (!value.companyType) {
        return new Yup.ValidationError("Required", null, "companyType")
      } else if (
        value.companyType === "other" &&
        !value.companyTypeDescription
      ) {
        return new Yup.ValidationError(
          "Required",
          null,
          "companyTypeDescription"
        )
      }
    }

    return true
  })

const COMPANY_TYPE_LABELS = {
  tow_company: "Tow Company",
  intrastate_operator: "Intrastate Operator",
  other: "Other",
}

const COMPANY_TYPE_OPTIONS = Object.keys(COMPANY_TYPE_LABELS)

const ROLE_OPTIONS = [
  "owner",
  "driver",
  "dispatcher",
  "supervisor",
  "accountant",
]

const trackCreateCarrierFinish = (formValues, responseValues = {}) => {
  const {
    roles,
    dotNumber,
    firstName,
    lastName,
    email,
    phone,
    companyName,
    companyType,
    companyTypeDescription,
  } = formValues
  const {
    carrierApproved = false,
    existingAccount = false,
    existingCarrier = false,
  } = responseValues
  const NOT_SET_LABEL = "n/a"
  const selectedRoles = []

  for (let role in roles) {
    if (roles[role]) {
      selectedRoles.push(role[0].toUpperCase() + role.slice(1))
    }
  }

  let companyDetail

  if (companyType) {
    if (companyType === "other") {
      companyDetail = companyTypeDescription
    } else {
      companyDetail = COMPANY_TYPE_LABELS[companyType]
    }
  } else {
    companyDetail = NOT_SET_LABEL
  }

  mixpanel.track("Create Company Finish", {
    "Roles Selected": selectedRoles,
    "Carrier Approved": dotNumber ? carrierApproved : NOT_SET_LABEL,
    "Exisiting Account": existingAccount,
    "Existing Carrier": existingCarrier,
    "Non-DOT Company": !dotNumber,
    "Company Detail": companyDetail,
    Name: `${firstName} ${lastName}`,
    Email: email,
    Phone: phone,
    "Carrier Name": companyName,
    Platform: "Website",
  })
}

const createCarrier = signupCarrierValues => {
  const {
    dotNumber,
    companyName,
    email,
    password,
    firstName,
    lastName,
    phone,
    roles,
    companyType,
    companyTypeDescription,
  } = signupCarrierValues

  const companyObj = {
    email,
    email_notifications_enabled: true,
    features: ["loadboard", "create_edit_orders", "driver_order_create_allow"],
    hide_user_list: true,
    is_carrier: true,
    name: companyName,
    phone_1: phone,
    billing: {
      invoice_enabled: true,
    },
  }

  if (dotNumber) {
    companyObj.dot_number = dotNumber
  } else {
    companyObj.type = companyType
    companyObj.type_description = companyTypeDescription
  }

  const userObj = {
    email,
    password,
    full_name: `${firstName} ${lastName}`,
    has_accepted_loadboard_terms: true,
    has_accepted_terms: true,
    phone_number: phone,
    primary_role: "Supervisor",
    roles: ["Supervisor"],
    truck_capacity: 8,
    truck_enclosed: false,
    truck_handles_inoperable: false,
  }

  if (roles.driver) {
    userObj.roles.push("Driver")
  }

  return axios.post("/api/create_carrier/", {
    company: companyObj,
    user: userObj,
  })
}

const ExclamationAnimation = () => {
  const animationContainerRef = React.useRef(null)

  React.useEffect(() => {
    if (animationContainerRef && animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: exclamationAnimation,
      })

      return () => {
        lottie.destroy()
      }
    }
  }, [])

  return <Box ref={animationContainerRef} maxWidth={154} mx="auto" />
}

const useStyles = makeStyles(theme => ({
  verticalDivider: {
    margin: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.common.black,
  },
  logosContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  logoImg: {
    marginBottom: theme.spacing(3),
  },
}))

export const SignUpCarrierTemplate = ({ data: { title }, statisticsData }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const fullScreenDialogs = useMediaQuery(theme.breakpoints.down("xs"))
  const [companyTypeOpen, setCompanyTypeOpen] = React.useState(false)
  const [existingAccountOpen, setExistingAccountOpen] = React.useState(false)
  const [existingCarrierOpen, setExistingCarrierOpen] = React.useState(false)

  return (
    <div>
      <BigForm
        title={title}
        subtitle={
          <Typography variant="subtitle2" align="center" color="textSecondary">
            <span>Not a Carrier?</span>{" "}
            <b>
              <Link component={AdapterLink} to="/sign-up-shipper">
                Click here
              </Link>
            </b>{" "}
            <span>for Shipper sign up.</span>
          </Typography>
        }
        form={
          <div>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                password: "",
                companyName: "",
                dotNumber: "",
                terms: false,
                roles: {
                  owner: false,
                  dispatcher: false,
                  accountant: false,
                  driver: false,
                  supervisor: false,
                },
                companyType: "",
                companyTypeDescription: "",
              }}
              validationSchema={SignupCarrierSchema}
              onSubmit={(values, { setSubmitting }) => {
                createCarrier(values)
                  .then(({ data }) => {
                    if (data.company.carrier_verified) {
                      trackCreateCarrierFinish(values, {
                        carrierApproved: true,
                      })
                    } else {
                      trackCreateCarrierFinish(values)
                    }

                    navigate("/sign-up-carrier-welcome", {
                      state: { formValues: values },
                    })
                  })
                  .catch(error => {
                    setSubmitting(false)
                    setCompanyTypeOpen(false)

                    let existingCarrier = false
                    let existingAccount = false

                    if (error.response && error.response.data) {
                      if (error.response.data.non_field_errors) {
                        for (const fieldError of error.response.data
                          .non_field_errors) {
                          const { type } = fieldError

                          if (type === "company_exists") {
                            setExistingCarrierOpen(true)
                            existingCarrier = true
                            break
                          } else if (type === "user_exists") {
                            setExistingAccountOpen(true)
                            existingAccount = true
                            break
                          }
                        }
                      } else if (error.response.data.company) {
                        enqueueSnackbar(
                          formatError({
                            response: {
                              data: error.response.data.company,
                            },
                          }),
                          {
                            variant: "error",
                          }
                        )
                      } else if (error.response.data.user) {
                        enqueueSnackbar(
                          formatError({
                            response: {
                              data: error.response.data.user,
                            },
                          }),
                          {
                            variant: "error",
                          }
                        )
                      }
                    }

                    trackCreateCarrierFinish(values, {
                      existingCarrier,
                      existingAccount,
                    })
                  })
              }}
            >
              {({
                values,
                touched,
                submitForm,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                errors,
              }) => {
                const handleCompanyTypeClose = () => {
                  setFieldValue("companyType", "")
                  setFieldValue("companyTypeDescription", "")

                  setCompanyTypeOpen(false)
                }

                return (
                  <div>
                    <Form>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Field
                            component={TextField}
                            name="firstName"
                            label="First Name"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            component={TextField}
                            name="lastName"
                            label="Last Name"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            component={TextField}
                            name="email"
                            type="email"
                            label="Email"
                            fullWidth
                            helperText="Will serve as login"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            component={TextField}
                            type="password"
                            label="Password"
                            name="password"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            component={TextField}
                            name="phone"
                            label="Phone"
                            fullWidth
                            InputProps={{
                              inputComponent: PhoneMaskedInput,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            component={TextField}
                            name="companyName"
                            label="Company Name"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            component={TextField}
                            name="dotNumber"
                            label="US DOT"
                            fullWidth
                            helperText="Required for quick approval"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            color={
                              errors.roles && touched.roles
                                ? "error"
                                : "textSecondary"
                            }
                          >
                            <span>My role is (select all that apply):</span>
                            {errors.roles === "Required" && touched.roles && (
                              <Box component="span" ml={1}>
                                <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                                <span>Required</span>
                              </Box>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={10} md={9} container>
                          {ROLE_OPTIONS.map(role => (
                            <Grid key={role} item xs={12} sm={5} md={6}>
                              <Field
                                color="primary"
                                component={CheckboxWithLabel}
                                name={`roles.${role}`}
                                type="checkbox"
                                Label={{
                                  label: role[0].toUpperCase() + role.slice(1),
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                        <Grid item xs={12}>
                          <CTAButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                            disabled={isSubmitting}
                            onClick={() => {
                              submitForm().catch(reason => {
                                if (
                                  Object.keys(reason).length === 1 &&
                                  reason.companyType
                                ) {
                                  setFieldTouched("companyType", false)
                                  setFieldTouched(
                                    "companyTypeDescription",
                                    false
                                  )
                                  setCompanyTypeOpen(true)

                                  mixpanel.track("Continue Non-DOT Company", {
                                    Platform: "Website",
                                  })
                                }
                              })
                            }}
                          >
                            Create Account
                          </CTAButton>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            color="primary"
                            component={CheckboxWithLabel}
                            name="terms"
                            type="checkbox"
                            Label={{
                              label: (
                                <Typography variant="body2">
                                  <span>I agree to Ship.Cars</span>{" "}
                                  <strong>
                                    <Link
                                      target="_blank"
                                      href="/legal/dashboard-terms-of-use"
                                    >
                                      Terms of Use
                                    </Link>{" "}
                                  </strong>
                                  <span>and</span>{" "}
                                  <strong>
                                    <Link
                                      target="_blank"
                                      href="/legal/privacy-policy"
                                    >
                                      Privacy Policy
                                    </Link>
                                  </strong>
                                  {errors.terms === "Required" &&
                                    touched.terms && (
                                      <Box
                                        component="span"
                                        ml={1}
                                        color="error.main"
                                        whiteSpace="nowrap"
                                      >
                                        <FontAwesomeIcon
                                          icon={faExclamationTriangle}
                                        />{" "}
                                        <span>Required</span>
                                      </Box>
                                    )}
                                </Typography>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Dialog
                        fullScreen={fullScreenDialogs}
                        maxWidth="xs"
                        open={companyTypeOpen}
                        onClose={handleCompanyTypeClose}
                      >
                        <DialogTitle onClose={handleCompanyTypeClose}>
                          Select Company Type
                        </DialogTitle>
                        <DialogContent>
                          {errors.companyType === "Required" &&
                            touched.companyType && (
                              <Typography color="error">
                                <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                                <span>Please make a selection.</span>
                              </Typography>
                            )}
                          {errors.companyTypeDescription === "Required" &&
                            touched.companyTypeDescription && (
                              <Typography color="error">
                                <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                                <span>Please enter your company type.</span>
                              </Typography>
                            )}
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            gutterBottom
                          >
                            <span>
                              Submitting without a DOT may exclude you from some
                              shippers’ loads.
                            </span>{" "}
                            <strong>
                              To expedite this process, please select your
                              company type:
                            </strong>
                          </Typography>
                          <Field component={RadioGroup} name="companyType">
                            {COMPANY_TYPE_OPTIONS.map(companyType => (
                              <FormControlLabel
                                key={companyType}
                                value={companyType}
                                control={
                                  <Radio
                                    color="primary"
                                    disabled={isSubmitting}
                                  />
                                }
                                label={COMPANY_TYPE_LABELS[companyType]}
                                disabled={isSubmitting}
                              />
                            ))}
                          </Field>
                          {values.companyType === "other" && (
                            <Box mt={4}>
                              <Field
                                component={TextField}
                                name="companyTypeDescription"
                                label="Company Type"
                                fullWidth
                              />
                            </Box>
                          )}
                          <Box maxWidth={250} mx="auto" mt={8}>
                            <Box my={1}>
                              <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                size="large"
                                disabled={isSubmitting}
                                onClick={submitForm}
                              >
                                Continue
                              </Button>
                            </Box>
                            <Box my={1}>
                              <Button
                                fullWidth
                                disabled={isSubmitting}
                                onClick={handleCompanyTypeClose}
                              >
                                Actually, I have a DOT
                              </Button>
                            </Box>
                          </Box>
                        </DialogContent>
                      </Dialog>
                    </Form>
                    <Dialog
                      fullScreen={fullScreenDialogs}
                      fullWidth
                      maxWidth="sm"
                      open={existingAccountOpen}
                      onClose={() => {
                        setExistingAccountOpen(false)
                      }}
                    >
                      <DialogTitle
                        onClose={() => {
                          setExistingAccountOpen(false)
                        }}
                      >
                        {fullScreenDialogs && "Error"}
                      </DialogTitle>
                      <DialogContent>
                        <Box maxWidth={460} mx="auto">
                          <ExclamationAnimation />
                          <Typography
                            variant="h5"
                            color="textPrimary"
                            align="center"
                            gutterBottom
                          >
                            <strong>Account already exists</strong>
                          </Typography>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                            align="center"
                            gutterBottom
                          >
                            <span>There’s already an account set up for</span>{" "}
                            <Box
                              component="span"
                              fontWeight="bold"
                              whiteSpace="nowrap"
                            >
                              {values.email}
                            </Box>{" "}
                            <span>or</span>{" "}
                            <Box
                              component="span"
                              fontWeight="bold"
                              whiteSpace="nowrap"
                            >
                              {values.phone}.
                            </Box>
                          </Typography>
                          <Box mt={8} mx="auto" maxWidth={250}>
                            <Box my={1}>
                              <Button
                                size="large"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                href="/app/login"
                              >
                                Login
                              </Button>
                            </Box>
                            <Box my={1}>
                              <Button
                                size="large"
                                fullWidth
                                href="tel:+18445227744"
                              >
                                Call Support
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </DialogContent>
                    </Dialog>
                    <Dialog
                      fullScreen={fullScreenDialogs}
                      fullWidth
                      maxWidth="sm"
                      open={existingCarrierOpen}
                      onClose={() => {
                        setExistingCarrierOpen(false)
                      }}
                    >
                      <DialogTitle
                        onClose={() => {
                          setExistingCarrierOpen(false)
                        }}
                      >
                        {fullScreenDialogs && "Error"}
                      </DialogTitle>
                      <DialogContent>
                        <Box maxWidth={460} mx="auto">
                          <ExclamationAnimation />
                          <Typography
                            variant="h5"
                            color="textPrimary"
                            align="center"
                            gutterBottom
                          >
                            <strong>Carrier already exists</strong>
                          </Typography>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                            align="center"
                            gutterBottom
                          >
                            <span>There’s already an account set up for</span>{" "}
                            <strong>{values.companyName}.</strong>
                          </Typography>
                          <Box mt={8} mx="auto" maxWidth={250}>
                            <Box my={1}>
                              <Button
                                size="large"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                href="tel:+18445227744"
                              >
                                Call Support
                              </Button>
                            </Box>
                            <Box my={1}>
                              <Button
                                size="large"
                                fullWidth
                                onClick={() => {
                                  setExistingCarrierOpen(false)
                                }}
                              >
                                Cancel
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </DialogContent>
                    </Dialog>
                  </div>
                )
              }}
            </Formik>
          </div>
        }
        info={
          statisticsData && (
            <div>
              <Box mb={6} display="flex" justifyContent="center">
                <img height={38} src={LogoFullWhite} alt="Ship.Cars logo" />
              </Box>
              <Statistics
                description="Leading Changes, Building Connections"
                data={statisticsData}
                collapsed
                inheritColor
              />
            </div>
          )
        }
      />
      <Action className="action-section">
        <AnimateOnSeen
          animeConfig={{
            ...TRANSLATE_ANIME_CONFIG,
            targets: `.custom-action`,
            delay: anime.stagger(100, { direction: "reverse" }),
          }}
        >
          <div className={classes.logosContainer}>
            <img
              className={classes.logoImg}
              height={60}
              src={LogoFullImg}
              alt="Ship.Cars logo"
            />
            <Hidden smDown>
              <Divider
                className={classes.verticalDivider}
                orientation="vertical"
                flexItem
              />
            </Hidden>
            <img
              className={classes.logoImg}
              height={60}
              src={DriverLogoFullImg}
              alt="Apple Store"
            />
          </div>
          <Box pt={6} display="flex" flexWrap="wrap" justifyContent="center">
            <Box m={1} className="custom-action">
              <Link href="https://apps.apple.com/us/app/ship-cars-more-than-an-epod/id1271063208">
                <img height={60} src={AppStoreBadgeImg} alt="Apple Store" />
              </Link>
            </Box>
            <Box m={1} className="custom-action">
              <Link href="https://play.google.com/store/apps/details?id=cars.ship.epod.mobile">
                <img height={60} src={GooglePlayBadgeImg} alt="Google Play" />
              </Link>
            </Box>
          </Box>
        </AnimateOnSeen>
      </Action>
    </div>
  )
}

const SignUpCarrierPage = ({
  data: { footerData, signUpCarrierPageData, statisticsData, siteData },
  location,
}) => {
  const { seo } = signUpCarrierPageData.frontmatter
  const { siteUrl } = siteData.siteMetadata

  React.useEffect(() => {
    mixpanel.track("Create Company Start", {
      Platform: "Website",
    })
  }, [])

  return (
    <React.Fragment>
      <Helmet
        title={seo.title}
        meta={[
          ...buildMeta(siteData.siteMetadata, seo, null, ""),
          ...(seo.metatags ? seo.metatags : []),
        ]}
      >
        <link rel="canonical" href={`${siteUrl}${location.pathname}`} />
        {seo.schemamarkup && (
          <script type="application/ld+json">{seo.schemamarkup}</script>
        )}
      </Helmet>
      <Page
        logo={{
          full: LogoCTMSFullImg,
          small: LogoCTMSSmallImg,
          alt: "Ship.Cars Carrier TMS",
          link: "/smarthaul-tms",
        }}
        headerData={{ frontmatter: {} }}
        footerData={footerData}
      >
        <SignUpCarrierTemplate
          data={signUpCarrierPageData.frontmatter}
          statisticsData={statisticsData}
        />
      </Page>
    </React.Fragment>
  )
}

export default SignUpCarrierPage

export const pageQuery = graphql`
  query SignUpCarrierPage {
    ...PageFragment
    ...StatisticsFragment
    siteData: site {
      siteMetadata {
        siteUrl
        env
      }
    }
    signUpCarrierPageData: markdownRemark(
      fileAbsolutePath: { regex: "/pages/sign-up-carrier.md/" }
    ) {
      frontmatter {
        title
        seo {
          title
          description
          keywords
          schemamarkup
          metatags {
            name
            content
          }
        }
      }
    }
  }
`
