import React from "react"
import MaskedInput from "react-text-mask"
import PropTypes from "prop-types"

const PhoneMaskedInput = ({ inputRef, ...other }) => (
  <MaskedInput
    {...other}
    ref={ref => {
      inputRef(ref ? ref.inputElement : null)
    }}
    mask={[
      "(",
      /\d/,
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]}
    placeholderChar={"\u2000"}
    showMask={false}
  />
)

PhoneMaskedInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

export default PhoneMaskedInput
